import React, { useState, useEffect } from 'react'
import {
  uploadFile,
  getMappingPropertiesData
} from '../../utils/networkControllers/RailsRequests/AssignmentsController'
import {
  Box,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox
} from '@mui/material'
import Loading from '../Loading'

const PropertiesMapStep = ({ uploadedFile, sheets, setSheets, selectedSheet, setSelectedSheet, prevStep }) => {
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [mappingProperties, setMappingProperties] = useState([])

  const tableHeaders = [
    { label: '#', width: '15%' },
    { label: 'File Column', width: '30%' },
    { label: 'Schema Property', width: '30%' },
    { label: 'Create Mapping Assignment?', width: '25%' }
  ]

  const handleSheetChange = (event) => {
    const newSheet = sheets.find(sheet => sheet.id === event.target.value)

    setSelectedSheet(newSheet)
    setErrorMessage(null)
  }

  const handleSchemaChange = (columnId, value) => {
    const schemaPropertyName = mappingProperties.find(prop => prop.id === value)?.name

    setSelectedSheet(prev => {
      const updatedSheet = {
        ...prev,
        columns: prev.columns.map(col =>
          col.id === columnId ? { ...col, schema_property_id: value, schema_property_name: schemaPropertyName } : col
        )
      }

      setSheets(prevSheets =>
        prevSheets.map(sheet => (sheet.id === updatedSheet.id ? updatedSheet : sheet))
      )

      return updatedSheet
    })
  }

  const handleAssignmentToggle = (columnId) => {
    setSelectedSheet(prev => {
      const column = prev.columns.find(col => col.id === columnId)

      if (!column.schema_property_id) {
        setErrorMessage('Select Schema Property First')

        return prev
      }

      setErrorMessage(null)

      const updatedSheet = {
        ...prev,
        columns: prev.columns.map(col =>
          col.id === columnId ? { ...col, selected: !col.selected } : col
        )
      }

      setSheets(prevSheets =>
        prevSheets.map(sheet => (sheet.id === updatedSheet.id ? updatedSheet : sheet))
      )

      return updatedSheet
    })
  }

  useEffect(() => {
    if (prevStep === 0 && uploadedFile) {
      setLoading(true)

      uploadFile({ file: uploadedFile })
        .then(response => {
          setSheets(response)
          setSelectedSheet(response[0])
        })
        .catch(error => {
          setErrorMessage('Error fetching file metadata.')
          console.error('Error:', error)
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [uploadedFile])

  useEffect(() => {
    setLoading(true)

    getMappingPropertiesData()
      .then(data =>
        setMappingProperties(data)
      )
      .catch(error =>
        console.error('Error fetching mapping properties:', error)
      )
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return <Loading />
  } else {
    return (
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, fontFamily: "'Comfortaa', sans-serif" }}>
        <span style={{ width: '100%', maxWidth: '900px', textAlign: 'left', fontSize: '14px' }}>Select Sheet</span>
        <hr style={{ width: '100%', maxWidth: '900px', borderTop: '1px solid #ccc' }} />

        <Box sx={{ width: '100%', maxWidth: '900px', mb: 2 }}>
          <FormControl fullWidth>
            <Select
              style={{ flex: 1, fontSize: '14px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', fontFamily: "'Comfortaa', sans-serif" }}
              value={selectedSheet?.id || ''}
              onChange={handleSheetChange}
            >
              {sheets.map(sheet => (
                <MenuItem key={sheet.id} value={sheet.id} style={{ fontSize: '14px', fontFamily: "'Comfortaa', sans-serif" }}>
                  {sheet.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <span style={{ width: '100%', maxWidth: '900px', textAlign: 'left', fontSize: '14px', marginTop: '25px' }}>Column Map</span>
        <hr style={{ width: '100%', maxWidth: '900px', borderTop: '1px solid #ccc' }} />

        <Paper elevation={2} sx={{ width: '100%', maxWidth: '900px', fontSize: '14px', padding: '10px 10px 20px 10px', border: '1px solid #ccc', borderRadius: '8px' }}>
          <Box sx={{ width: '100%' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map(header => (
                      <TableCell key={header.label} sx={{ width: header.width, fontSize: '14px', fontFamily: "'Comfortaa', sans-serif" }}>
                        {header.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedSheet?.columns.map((col, index) => (
                    <TableRow key={col.id}>
                      <TableCell sx={{ width: '15%', fontSize: '14px', fontFamily: "'Comfortaa', sans-serif" }}>{index + 1}</TableCell>
                      <TableCell sx={{ width: '30%', fontSize: '14px', fontFamily: "'Comfortaa', sans-serif" }}>{col.name}</TableCell>
                      <TableCell sx={{ width: '30%' }}>
                        <Select
                          value={col.schema_property_id || ''}
                          onChange={(e) => handleSchemaChange(col.id, e.target.value)}
                          fullWidth
                          sx={{ fontSize: '14px', fontFamily: "'Comfortaa', sans-serif" }}
                        >
                          {mappingProperties.map(property => (
                            <MenuItem key={property.id} value={property.id} sx={{ fontSize: '14px', fontFamily: "'Comfortaa', sans-serif" }}>
                              {property.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell sx={{ width: '25%', textAlign: 'center' }}>
                        <Checkbox
                          style={{ color: '#ffc700' }}
                          checked={col.selected || false}
                          onChange={() => handleAssignmentToggle(col.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {errorMessage && (
            <center className='error-message' style={{ width: 'auto', margin: '30px 30% 30px 30%'}}>{errorMessage}</center>
          )}
        </Paper>
      </Box>
    )
  }
}

export default PropertiesMapStep
