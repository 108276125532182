import React, { useEffect, useState } from 'react'
import { Alert, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { createAssignments } from '../../utils/networkControllers/RailsRequests/AssignmentsController'
import Loading from '../Loading'

const CompletionStep = ({ sheets }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)

    createAssignments(sheets)
      .then(response => {
        if (response.success) {
          setErrorMessage(null)
          setSuccess(true)
        } else {
          setErrorMessage(response.detail)
          setSuccess(false)
        }
      })
      .catch(error => {
        setSuccess(false)
        setErrorMessage('Error while finalizing file upload.')
        console.error('Error:', error)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <Box sx={{ textAlign: 'center', maxWidth: '600px', margin: 'auto', p: 2 }}>
      {loading && (
        <>
          <Alert severity='warning' sx={{ mb: 2 }} style={{ fontSize: '14px', fontFamily: "'Comfortaa', sans-serif" }}>
            Your data is being processed; please do not close this tab
          </Alert>
          <Loading />
        </>
      )}

      {!loading && success && (
        <Alert severity='success' style={{ fontSize: '14px', fontFamily: "'Comfortaa', sans-serif" }}>
          Your data has been uploaded. You can start working on it{' '}
          <span
            style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => navigate('/')}
          >
            here!
          </span>
        </Alert>
      )}

      {!loading && errorMessage && <Alert severity='error' style={{ fontSize: '14px', fontFamily: "'Comfortaa', sans-serif" }}>{errorMessage}</Alert>}
    </Box>
  )
}

export default CompletionStep
