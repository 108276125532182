import React from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

const SummarizationStep = ({ sheets }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
        fontFamily: "'Comfortaa', sans-serif",
      }}
    >
      <span
        style={{
          width: '100%',
          maxWidth: '900px',
          textAlign: 'left',
          fontSize: '14px',
        }}
      >
        Summarization
      </span>
      <hr
        style={{
          width: '100%',
          maxWidth: '900px',
          borderTop: '1px solid #ccc',
        }}
      />

      {sheets.map((sheet) => (
        <Paper
          key={sheet.id}
          elevation={2}
          sx={{
            width: '100%',
            maxWidth: '900px',
            fontSize: '14px',
            padding: '10px 10px 20px 10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            mb: 3,
          }}
        >
          <span style={{ display: 'block', width: '100%', maxWidth: '900px', textAlign: 'center', fontSize: '14px' }}>{sheet.name}</span>

          <Box sx={{ width: '100%' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ fontSize: '14px', fontFamily: "'Comfortaa', sans-serif", textAlign: 'center' }}
                    >
                      Column Name
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '14px', fontFamily: "'Comfortaa', sans-serif", textAlign: 'center' }}
                    >
                      Schema Property
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: '14px', fontFamily: "'Comfortaa', sans-serif", textAlign: 'center' }}
                    >
                      Create Mapping Assignment?
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sheet.columns.map((column, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          fontSize: '14px',
                          fontFamily: "'Comfortaa', sans-serif",
                          textAlign: 'center'
                        }}
                      >
                        {column.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '14px',
                          fontFamily: "'Comfortaa', sans-serif",
                          textAlign: 'center'
                        }}
                      >
                        {column.schema_property_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '14px',
                          fontFamily: "'Comfortaa', sans-serif",
                          textAlign: 'center'
                        }}
                      >
                        {column.selected ? <i className={'fa-solid fa-square-check'} style={{ color: '#ffc700' }} /> : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      ))}
    </Box>
  )
}

export default SummarizationStep
