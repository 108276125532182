import { createSlice, current } from '@reduxjs/toolkit'

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    current_user: null,
    current_assignment: null,
    current_mapping_entity_type: null,
    mapping_status_filters: [],
    error_message: null
  },
  reducers: {
    set_current_user: (state, action) => {
      state.current_user = action.payload
    },
    set_current_assignment: (state, action) => {
      state.current_assignment = action.payload
    },
    set_current_mapping_entity_type: (state, action) => {
      state.current_mapping_entity_type = action.payload
    },
    set_mapping_status_filters: (state, action) => {
      state.mapping_status_filters = action.payload
    },
    set_error_message: (state, action) => {
      state.error_message = action.payload
    }
  }
})

export const {
  set_current_user,
  set_current_assignment,
  set_current_mapping_entity_type,
  set_mapping_status_filters,
  set_error_message
} = generalSlice.actions

export default generalSlice.reducer
