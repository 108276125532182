import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Stepper, Step, StepLabel, Button, Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import FileUploadStep from './FileUploadStep'
import PropertiesMapStep from './PropertiesMapStep'
import SummarizationStep from './SummarizationStep'
import CompletionStep from './CompletionStep'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: '50px',
    fontFamily: "'Comfortaa', sans-serif !important"
  },
  customLabelStyle: {
    display: 'flex !important',
    justifyContent: 'center !important',
    fontSize: '14px !important',
    fontWeight: 700,
    fontFamily: "'Comfortaa', sans-serif !important",
    alignItems: 'center',
    gap: '8px !important',
    '&.Mui-active': {
      textDecoration: 'underline',
      textDecorationColor: '#FFC107',
      textDecorationThickness: '2px'
    },
  },
  stepIcon: {
    fontSize: '26px !important',
    color: '#BDBDBD !important',
    '&.Mui-active': {
      color: '#FFC107 !important',
      transform: 'scale(1.2)'
    },
    '&.Mui-completed': {
      color: '#BDBDBD !important'
    }
  },
  stepConnector: {
    '& .MuiStepConnector-line': {
      borderColor: '#BDBDBD'
    }
  }
}))

const UploadWizard = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const steps = [
    { label: 'File Upload', icon: '<i class="fas fa-file-upload"></i>' },
    { label: 'Properties Map', icon: '<i class="fas fa-th"></i>' },
    { label: 'Summarize', icon: '<i class="fas fa-check-circle"></i>' },
    { label: 'Complete', icon: '<i class="fas fa-cloud-upload-alt"></i>' }
  ]

  const [activeStep, setActiveStep] = useState(0)
  const [prevStep, setPrevStep] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [sheets, setSheets] = useState([])
  const [selectedSheet, setSelectedSheet] = useState(null)

  const handleCancel = () => {
    navigate('/')
  }

  const handleNext = () => {
    setPrevStep(activeStep)
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleBack = () => {
    setPrevStep(activeStep)
    setActiveStep((prevStep) => prevStep - 1)
  }

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <FileUploadStep selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
      case 1:
        return <PropertiesMapStep uploadedFile={selectedFile} sheets={sheets} setSheets={setSheets} selectedSheet={selectedSheet} setSelectedSheet={setSelectedSheet} prevStep={prevStep}/>
      case 2:
        return <SummarizationStep sheets={sheets} />
      case 3:
        return <CompletionStep sheets={sheets} />
      default:
        return <div>Unknown Step</div>
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={false} md={2} lg={2} />
          <Grid item sm={12} md={8} lg={8}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={classes.stepConnector}
            >
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel
                    StepIconProps={{
                      classes: { root: classes.stepIcon },
                    }}
                    classes={{ label: classes.customLabelStyle }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: step.icon }} />
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item sm={false} md={2} lg={2} />
        </Grid>
      </div>

      <Box
        sx={{
          margin: 'auto',
          maxWidth: '1000px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: "16px",
          mb: 3,
          border: "1px solid #ccc",
          backgroundColor: "#fff",
          borderRadius: "8px"
        }}
      >
        {renderStepContent(activeStep)}
      </Box>

      <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px', marginBottom: '20px' }}>
        {activeStep === 0 ? (
          <Button
            style={{
              width: '150px',
              padding: '6px 8px 6px 8px',
              backgroundColor: '#ffc700',
              borderRadius: '5px',
              fontFamily: "'Comfortaa', sans-serif",
              color: 'white',
              fontSize: '13px',
              '&:hover': { backgroundColor: '#E0A800' }
            }}
            onClick={handleCancel}
          >
            <i className='fa-solid fa-angle-left' />
            <span className='text' style={{ textTransform: 'none' }}>
              Cancel
            </span>
          </Button>
        ) : (
          <Button
            style={{
              width: '150px',
              padding: '6px 8px 6px 8px',
              backgroundColor: '#ffc700',
              borderRadius: '5px',
              fontFamily: "'Comfortaa', sans-serif",
              color: 'white',
              fontSize: '13px',
              '&:hover': { backgroundColor: '#E0A800' }
            }}
            onClick={handleBack}
          >
            <i className='fa-solid fa-angle-left' />
            <span className='text' style={{ textTransform: 'none' }}>
              Back
            </span>
          </Button>
        )}
        <Button
          style={{
            width: '150px',
            padding: '6px 8px 6px 8px',
            backgroundColor: selectedFile ? '#ffc700' : '#ccc',
            borderRadius: '5px',
            fontFamily: "'Comfortaa', sans-serif",
            color: 'white',
            fontSize: '13px',
            '&:hover': { backgroundColor: '#E0A800' }
          }}
          onClick={handleNext}
          disabled={!selectedFile}
        >
          <span className='text' style={{ textTransform: 'none' }}>
            Proceed
          </span>
          <i className='fa-solid fa-angle-right' style={{ marginLeft: '5px' }}/>
        </Button>
      </div>
    </>
  )
}

export default UploadWizard
