import React from 'react'
import { Box, Button, Paper } from '@mui/material'

const FileUploadStep = ({ selectedFile, setSelectedFile }) => {
  const blueColor = '#0071ff'

  const handleFileChange = (event) => {
    const file = event.target.files[0]

    if (file) {
      setSelectedFile(file)
    }
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]

    if (file) {
      setSelectedFile(file)
    }
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, fontFamily: "'Comfortaa', sans-serif" }}>
      <span style={{ width: '100%', maxWidth: '900px', fontWeight: 'bold', textAlign: 'left', fontFamily: "'Comfortaa', sans-serif" }}>
        UPLOAD YOUR FILE
      </span>
      <hr style={{ width: '100%', maxWidth: '900px', borderTop: '1px solid #ccc' }} />

      <Paper
        elevation={2}
        sx={{
          width: '100%',
          maxWidth: '900px',
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px dashed #ccc',
          borderRadius: '8px',
          mt: 2,
          marginBottom: '20px',
          textAlign: 'center'
        }}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        <i className='fa-solid fa-cloud-arrow-up fa-5x' style={{ color: blueColor, marginBottom: '15px', marginTop: '50px' }} />

        <hr style={{ width: '50%', border: `0.5px solid ${blueColor}`, margin: '10px 0' }} />

        <span
          style={{ color: blueColor, fontWeight: 'bold', cursor: 'pointer', fontFamily: "'Comfortaa', sans-serif", fontSize: '13px' }}
        >
          Drag & Drop Your File(s) Here To Upload
        </span>

        <hr style={{ width: '50%', border: `1px solid ${blueColor}`, margin: '10px 0' }} />

        <input
          type='file'
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id='file-upload-input'
        />

        <Button
          variant='outlined'
          sx={{
            fontFamily: "'Comfortaa', sans-serif",
            fontSize: '13px',
            mt: 2,
            borderRadius: '20px',
            borderColor: blueColor,
            color: blueColor,
            '&:hover': {
              backgroundColor: blueColor,
              color: 'white'
            }
          }}
          onClick={() => document.getElementById('file-upload-input').click()}
        >
          <span style={{ textTransform: 'none' }}>
            or select file to upload
          </span>
        </Button>

        {selectedFile && (
          <span style={{ color: 'green', fontFamily: "'Comfortaa', sans-serif", fontSize: '13px', marginTop: '10px' }}>
            Selected File: {selectedFile.name}
          </span>
        )}
      </Paper>
    </Box>
  )
}

export default FileUploadStep
